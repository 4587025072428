import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layouts/en"
import BlogTemplate from "../../templates/blog/_index"

const IndexPage = props => {
  const { title, subtitle } = props.data.markdownRemark.frontmatter

  return (
    <Layout location={props.location} title={title} description={subtitle}>
      <BlogTemplate {...props} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query BlogPageEN {
    markdownRemark(
      fileAbsolutePath: { regex: "/static-pages/blog/index.en.md/" }
    ) {
      frontmatter {
        ...BlogPage
      }
    }
    blogPosts: allBlogPostEn {
      nodes {
        published_at
        id
        title
        content
        image {
          title
          url
        }
      }
    }
  }
`
